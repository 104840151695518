import React, { useEffect, useState, useContext } from "react";
import Input from "../../../../../components/Input/Input";
import Select from "../../../../../components/Select/Select";
import { useLocation } from "react-router-dom";

const EditSchoolDetails = () => {
  const location = useLocation();
  const school = location.state.school[0];
  const [loading, setLoading] = useState(false);
  console.log(school);

  const [name, setName] = useState(school?.name || "");
  const [state, setState] = useState(school?.address?.state || "");
  const [type, setType] = useState(school?.school_type || "");

  const [students, setStudents] = useState(school?.student_count || "");
  const [edviron_Reg_Number, setEdviron_Reg_Number] = useState(
    school?.edviron_id || ""
  );

  const [street, setStreet] = useState(school?.address?.street || "");
  const [city, setCity] = useState(school?.address?.city || "City");
  const [pin, setPin] = useState(school?.address?.pin || "154154");
  const [phone_number, setPhoneNumber] = useState(school?.phone_number || "");
  const [email, setEmail] = useState(school?.email_id || "");

  const [account_holder, setAccountHolder] = useState(
    school?.bank_details?.account_holder_name || ""
  );
  const [account_number, setAccountNumber] = useState(
    school?.bank_details?.account_number || ""
  );
  const [ifsc, set_ifsc] = useState(
    school?.bank_details?.account_ifse_code || ""
  );
  const [fee_collection_date, set_fee_collection_date] = useState(
    school?.fee_collection_date || ""
  );

  const update_school = (data: any) => {
    setLoading(true);
    return new Promise(async (resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify(data);
      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      let res = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/school/update",
        requestOptions
      );
      if (res.ok) {
        const data = await res.json();
        setLoading(false);
        window.location.replace(`/school_profile/${school?._id}`);
        resolve(data);
      } else {
        const message = await res.json();
        if (message.statusCode === 401) {
          setLoading(false);
          localStorage.removeItem("token");
          window.location.reload();
        }
        reject(new Error("something wrong!"));
      }
    });
  };
  return (
    <>
      <div className="text-2xl  text-violet-600">Edit Details</div>
      <div className="my-3 h-[1px] w-full bg-[#6F6AF8]"></div>

      <div className="w-full mt-10 ">
        <div className="mt-6 flex items-center">
          <div className="w-[15%]">School Name</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3 ">
              <Input
                type="text"
                add_error={() => {}}
                value={name}
                onChange={(k: string, v: string) => setName(v)}
              />
            </div>

            <div className="text-right">School Type</div>
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={type}
                onChange={(k: string, v: string) => setType(v)}
              />
            </div>
          </div>
        </div>

        <div className=" mt-6 flex items-center">
          <div className="w-[15%]">Edviron Id</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={edviron_Reg_Number}
                onChange={(k: string, v: string) => setEdviron_Reg_Number(v)}
                disabled
              />
            </div>
            <div className="text-right">Date of Registration</div>
            <div className="w-1/3">
              <Input
                type="text"
                value={new Date(school?.createdAt).toDateString()}
                add_error={() => {}}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center">
          <div className="w-[15%]">Street</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={street}
                onChange={(k: string, v: string) => setStreet(v)}
              />
            </div>
            <div className="text-right">City</div>
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={city}
                onChange={(k: string, v: string) => setCity(v)}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center">
          <div className="w-[15%]">State</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={state}
                onChange={(k: string, v: string) => setState(v)}
              />
            </div>
            <div className="text-right">Pincode</div>
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={pin}
                onChange={(k: string, v: string) => setState(v)}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center">
          <div className="w-[15%]">Fee Collection Date</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Select
                selected={{ name: fee_collection_date }}
                setSelected={(v: any) => set_fee_collection_date(v.name)}
                options={[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
                ].map((d) => {
                  return { name: d };
                })}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 font-semibold text-2xl text-violet-600">
          Primary Contact
        </div>

        <div className="mt-6 flex items-center">
          <div className="w-[15%]">Contact Number</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={phone_number}
                onChange={(k: string, v: string) => setPhoneNumber(v)}
              />
            </div>
            <div className="text-right">Email Address</div>
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={email}
                onChange={(k: string, v: string) => setEmail(v)}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 font-semibold text-2xl text-violet-600">
          Bank Details
        </div>

        <div className="mt-6 flex items-center">
          <div className="w-[15%]">Account Holder Name</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={account_holder}
                onChange={(k: string, v: string) => setAccountHolder(v)}
              />
            </div>
            <div className="text-right">Accout Number</div>
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={account_number}
                onChange={(k: string, v: string) => setAccountNumber(v)}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center">
          <div className="w-[15%]">Accoutn IFSC Code</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3">
              <Input
                type="text"
                add_error={() => {}}
                value={ifsc}
                onChange={(k: string, v: string) => set_ifsc(v)}
              />
            </div>
          </div>
        </div>

        <div className="text-right">
          <button
            onClick={async () => {
              const res = update_school({
                school_id: school?._id,
                name,
                state,
                school_type: type,
                students,
                address: {
                  street: street,
                  city: city,
                  state: state,
                  pin: pin,
                },
                phone_number,
                email,
                fee_collection_date,
                bank_details: {
                  account_holder_name: account_holder,
                  account_number: account_number,
                  account_ifse_code: ifsc,
                },
              });
              console.log(await res);
            }}
            className="mt-6 py-2 px-4 max-w-[15rem] w-full rounded-md bg-[#6F6AF8] text-white"
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              "Update School Profile"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditSchoolDetails;